import React, { useEffect } from 'react';
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import clsx from 'clsx';

import { SuccessLocationState } from '../types';
import useAuth from '../../../../hooks/useAuth';
import ROUTES from '../../../../routes';
import theme from '../../../../themes/theme';
import colors from '../../../../themes/main-colors';
import useContentMgmt from '../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../pge-plus-common/microcopyGroupIds';
import { useIsMobile } from '../../../../util/style-utils';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '60%',
      margin: '68px auto 68px auto',
      [theme.breakpoints.down('sm')]: {
        margin: '24px auto 24px auto',
        width: '100%',
      },
    },
    heading: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '43px',
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '29px',
      },
    },
    bodyText: {
      fontSize: '20px',
      lineHeight: '27px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    highlighted: {
      color: colors.sparkBlue,
      fontWeight: 'bold',
      cursor: 'pointer',
      marginLeft: '4px',
    },
    button: {
      '&, &:hover': {
        backgroundColor: colors.buttonColor,
        borderRadius: '40px',
        color: colors.white,
        fontWeight: 500,
        fontSize: '20px',
        padding: '4px 32px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
          padding: '8px 20px',
        },
      },
      '&:hover': {
        backgroundColor: colors.buttonColor,
      },
    },
  }),
);

export const Success: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const state = location?.state as SuccessLocationState;
  const classes = useStyles();
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_CONSULTATION);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!state?.name || !state?.email) {
      void navigate(
        isAuthenticated
          ? ROUTES.PGE_PLUS_CONSULTATION_CHOOSE_ACCOUNT
          : ROUTES.PGE_PLUS_CONSULTATION_SELECT_ADDRESS,
      );
    }
  }, [state]);

  return (
    <Box className={classes.container}>
      <Box mb="36px">
        <Typography component="h1" align="center" className={classes.heading}>
          {content.get('NICE_WORK')}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.bodyText} css={{ marginBottom: '28px' }}>
          {content.get('SUCCESS_TEXT')}
        </Typography>
        <Typography className={classes.bodyText}>
          {content.get('YOUR_CONTACT_INFORMATION')}
          <br />
          <br />
          {state?.name}
          <br />
          {state?.email}
          {state?.phone && (
            <>
              <br />
              {state?.phone}
            </>
          )}
        </Typography>
        <Typography
          className={classes.bodyText}
          css={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          {content.get('NEED_HELP_EMAIL_US')}{' '}
          <Typography
            component="a"
            className={clsx(classes.bodyText, classes.highlighted)}
            onClick={() => {
              const email = 'user@example.com';
              window.location.href = `mailto:${email}`;
            }}
          >
            {content.get('PGE_PLUS_EMAIL')}
          </Typography>
        </Typography>
      </Box>
      <Divider variant="fullWidth" css={{ margin: '48px 0' }} />
      <Box
        display="flex"
        justifyContent="center"
        onClick={() =>
          isAuthenticated ? navigate(ROUTES.ACCOUNT) : navigate(ROUTES.HOME)
        }
      >
        <Button className={classes.button}>{content.get('CLOSE')}</Button>
      </Box>
    </Box>
  );
};
