import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from '@reach/router';
import colors from '../../../themes/main-colors';
import PGEButton from '../../buttons';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import {
  GetJobOutputEvType,
  useInstallerJobDetailsQuery,
} from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import Backdrop from '../../backdrop/Backdrop';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';
import { useIsMobile } from '../../../util/style-utils';
import InstallerDateAndTime from '../installer-common/InstallerDateAndTime';
import QuoteCustomerInfo from '../installer-common/quote-customer-info';
import JobInstallationCost from '../installer-common/jobInstallationCost';
import QuoteTotalSection from '../installer-common/quoteTotalSection';
import { JobQuoteModal } from '../confirm-cost/QuoteForm.rules';
import useQuote from '../confirm-cost/useQuote';
import {
  ApplicationEv,
  ApplicationEvSectionType,
  LineItemCost,
  LineItemProduct,
  QuoteDataEv,
  SectionEvChargerSelection,
  SectionEvEstimate,
  SectionEvInstall,
  SectionEvPanelInfo,
  SectionEvRebates,
} from '../../../__generated__/pgeplus-types';
import { titleCase } from '../../../util/string-utils';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { LINE_ITEM_KEY } from '../quote.types';

function getDefaultJobValues(
  jobData: GetJobOutputEvType,
  defaultFormState: JobQuoteModal,
): JobQuoteModal {
  //
  const selectCharger = (jobData.application as ApplicationEv)?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvChargerSelection,
  ) as SectionEvChargerSelection;
  const eligibilityCheck = (jobData.application as ApplicationEv)?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvRebates,
  ) as SectionEvRebates;
  const evInstall = jobData.application?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvInstall,
  ) as SectionEvInstall;
  const panelInfo = jobData.application?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvPanelInfo,
  ) as SectionEvPanelInfo;
  const estimate = jobData.application?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvEstimate,
  ) as SectionEvEstimate;
  const eligibility = eligibilityCheck?.data?.rebateEligibility?.eligible
    ? 'Yes'
    : 'No';

  const costCharger = estimate?.data?.lineItems?.find(
    item => item.key === selectCharger?.data?.productId,
  ) as LineItemProduct;
  const installCost = estimate?.data?.lineItems?.find(
    item => item.key === 'ESTIMATED_INSTALLATION_COST',
  ) as LineItemCost;

  const adminFee = estimate?.data?.lineItems?.filter(
    (item: any) =>
      item.__typename === 'LineItemCost' &&
      item.key === LINE_ITEM_KEY.ADMIN_FEE,
  )?.[0] as LineItemCost;

  return {
    ...defaultFormState,
    preferedDay: evInstall?.data?.schedulePreference
      ?.dayOfWeek!.map(arr => titleCase(arr))
      .join(' , '),
    preferedTime: evInstall?.data?.schedulePreference
      ?.timeFrame!.map(arr => titleCase(arr))
      .join(' , '),
    panelLocation: evInstall?.data?.panelLocation?.location!,
    distance: evInstall?.data?.chargerInstallPreference?.distanceToPanel!,
    outsidePanelLocation: evInstall?.data?.chargerInstallPreference
      ?.panelSideLocation!,
    additionalComment: evInstall?.data?.panelLocation?.comment!,
    isDistance: evInstall?.data?.chargerInstallPreference
      ?.installerRecommendPanelDistance
      ? 'yes'
      : 'no',
    amperage: panelInfo?.data?.breakerInfo?.mainBreakerAmperage!,
    chargerModel: selectCharger?.data?.productId!,
    chargerCost: costCharger?.amount.toFixed(2),
    rebateEligibility: eligibility,
    isCreateQuote: true,
    totalInstallationMaterialCost: installCost?.amount || 0,
    adminFee: adminFee && adminFee?.amount ? adminFee?.amount || 0 : undefined,
  };
}

const CompleteQuote = () => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_COMPLETE_QUOTE,
  );
  const theme = useTheme();
  const params = useParams();
  const { loading, data, error } = useInstallerJobDetailsQuery(params?.jobId);
  const { setErrorNotification } = useUtil();

  const jobData = data?.getJob.job;
  const applicationData = data?.getJob.application;
  const [isPanelUpgrade, setIsPanelUpgrade] = useState<boolean>();
  const {
    totalInstallationMaterialCost,
    totalInstallation,
    totalCost,
    costBeforeRebate,
    setTotalInstallationMaterialCost,
    isUpdate,
    rebateAmount,
    jobQuoteForm,
    resetExpandFormData,
    calculateTotalAmount,
    submitCreateQuote,
    rebateCalculate,
    defaultFormState,
    chargerModelList,
    setChargerModelList,
    isCalculatingRebates,
  } = useQuote(content);
  const getDefaultValues = async () => {
    jobQuoteForm.reset(getDefaultJobValues(data?.getJob!, defaultFormState));
    const estimate = applicationData?.details?.find(
      sec => sec.sectionType === ApplicationEvSectionType.EvEstimate,
    ) as SectionEvEstimate;
    const installCost = estimate?.data?.lineItems?.find(
      item => item.key === 'ESTIMATED_INSTALLATION_COST',
    ) as LineItemCost;
    const selectCharger = (applicationData as ApplicationEv)?.details?.find(
      sec => sec.sectionType === ApplicationEvSectionType.EvChargerSelection,
    ) as SectionEvChargerSelection;
    const costCharger = estimate?.data?.lineItems?.find(
      item => item.key === selectCharger?.data?.productId,
    ) as LineItemProduct;

    calculateTotalAmount(false, {
      totalInstallationMaterial: Number(installCost?.amount || 0),
      chargerCost: costCharger.amount || 0,
      meterBase: 0,
      weatherHead: 0,
      electricBreaker: 0,
      miscMaterial: 0,
      labor: 0,
      material: 0,
      permitCost: 0,
      travelCost: 0,
      feesCost: 0,
      miscellaneousWorkCost: 0,
    });
  };
  useEffect(() => {
    if (jobData) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getDefaultValues();
    }
  }, [applicationData]);

  useEffect(() => {
    if (!(jobData?.quote?.data as QuoteDataEv)?.declineEnrollment) {
      void rebateCalculate(applicationData!);
    }
  }, [applicationData, isPanelUpgrade, isUpdate, jobData]);

  const hr = {
    border: '1px solid #D7D7D7',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  };

  const button = {
    background: colors.orange,
    width: '200px',
    borderRadius: '30px',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: colors.orange,
    },
  };

  const isMobile = useIsMobile();

  return (
    <Fragment>
      {loading && <Backdrop forceOpen />}
      <Box
        display="flex"
        flexDirection="column"
        mt={1}
        css={{
          color: colors.noirBlurLight,
          maxWidth: '706px',
          margin: 'auto',
          justifyContent: 'center',
          '& .MuiFormControl-root': {
            background: 'transparent',
          },
          '& .MuiInputBase-input': {
            background: '#FFF',
          },
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <InstallerPageHeader
              pageHeaderInfo={{
                pageTitle: content.get('COMPLETE_QUOTE') || '',
                pageSubTitle: content.get('COMPLETE_QUOTE_SUBTEXT'),
              }}
              jobData={data?.getJob!}
              content={content}
            />
            <QuoteCustomerInfo
              content={content}
              applicationNo={applicationData?.applicationNo!}
              jobData={jobData?.jobLocation!}
            />
            <hr css={{ ...hr }} />
            <JobInstallationCost
              visibleNotes={true}
              content={content}
              applicationData={applicationData}
              title={content.get('COMPLETE_FORM_TO_GENERATE_QUOTE')}
              resetExpandFormData={resetExpandFormData}
              calculateTotalAmount={calculateTotalAmount}
              jobQuoteForm={jobQuoteForm}
              setIsPanelUpgrade={setIsPanelUpgrade}
              totalInstallationMaterialCost={totalInstallationMaterialCost}
              totalInstallationCost={totalInstallation}
              chargerModelList={chargerModelList}
              setChargerModelList={setChargerModelList}
            />
            <hr css={{ ...hr }} />
            <QuoteTotalSection
              content={content}
              rebateAmount={rebateAmount}
              totalCost={totalCost}
              costBeforeRebate={costBeforeRebate}
              isCalculatingRebates={isCalculatingRebates}
            />
            <hr css={{ ...hr }} />
            <Grid>
              <Typography
                css={{
                  fontSize: '14px',
                  color: '#3D5265',
                  marginBottom: theme.spacing(2),
                }}
              >
                {content.get('LEGAL_TEXT')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} css={{ margin: theme.spacing(4, 0, 0, 0) }}>
            <Box justifyContent={'center'} display={'flex'}>
              <PGEButton
                data-testid={'pay-online-button'}
                onClick={jobQuoteForm.submit(async values => {
                  try {
                    await submitCreateQuote(params?.jobId);
                  } catch (e) {
                    setErrorNotification(true);
                  }
                })}
                size={'large'}
                css={{ ...button }}
                disabled={isCalculatingRebates}
              >
                {content.get('SUBMIT')}
              </PGEButton>
            </Box>
          </Grid>
          <hr css={{ ...hr }} />
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CompleteQuote;
