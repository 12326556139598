import { jsx, css } from '@emotion/core';

import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { AlertProps } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  createStyles,
  Collapse,
  Box,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import colors from '../../../themes/main-colors';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useLocation } from '@reach/router';

import {
  AccountDetail,
  AccountRelationship,
} from '../../../__generated__/pge-types';
import {
  Application,
  ApplicationStatus,
  ApplicationType,
  JobStatus,
  QuoteStatus,
} from '../../../__generated__/pgeplus-types';
import ROUTES from '../../../routes';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useIsMobile } from '../../../util/style-utils';
import { toCurrencyString } from '../../../util/format';
import useAuthQuery from '../../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useEVApplicationApi from '../../../hooks/pge-plus/useEVApplicationApi';
import { getPOPUrl } from '../../pge-plus/pay-out-of-pocket/utils/navigation.util';
import { formatDateString } from '../../../util/date-utils';
import { getAccountDetails } from '../../account-summary/single-account/queries';

import IconChargerLevel2 from '../../../components/pge-plus-common/static/evChargerIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px 1px 1px #00000066',
      border: `10px solid ${colors.riverBlue}`,
      borderRadius: '4px',
      backgroundColor: colors.white,
      marginBottom: '2em',
      padding: '20px 22px 16px 18px',
      cursor: 'pointer',
      '& .cls-1': {
        fill: 'none', // This is to override fill: #fff from the twitterX icon used in PgeFooter component
      },
    },
    textContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

const PendingApplicationBanner = (props: AlertProps) => {
  const classes = useStyles();
  const { bodyContainer, textContent } = classes;
  const { content, inlineRichText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_APPLICATION_STATUS_DASHBOARD_CARD,
  );
  const isMobile = useIsMobile();
  const location = useLocation();

  const isBillPaymentRoute = location.pathname === ROUTES.PAYMENT;

  const { getApplicationsByAccount } = useEVApplicationApi();

  const { accountParams } = useSelectedAccountParams();

  const { loading: hookLoading, data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const [applicationInView, setApplicationInView] = useState<Application>();

  const amountDue = applicationInView?.job?.charges?.amountDue || 0;

  useEffect(() => {
    if (
      !hookLoading &&
      data?.getAccountDetails &&
      (data?.getAccountDetails?.[0]?.relationType ===
        AccountRelationship.Main ||
        data?.getAccountDetails?.[0]?.relationType ===
          AccountRelationship.Coapp)
    ) {
      void getApplicationsData();
    }
  }, [accountParams?.accountNumber, data, hookLoading]);

  const getApplicationsData = async () => {
    if (accountParams?.accountNumber) {
      const { data: accountData } = await getApplicationsByAccount({
        accountId: [accountParams.accountNumber],
        applicationTypes: [ApplicationType.Er, ApplicationType.Ev],
        status: [
          ApplicationStatus.Created,
          ApplicationStatus.Submitted,
          ApplicationStatus.InProgress,
          ApplicationStatus.Servicing,
          ApplicationStatus.Completed, // For last amount payment, the application will be in Completed state
        ],
      });
      const dataToConsider = accountData?.getApplications?.applications?.filter(
        application =>
          (application.applicationType === ApplicationType.Er
            ? application.status !== ApplicationStatus.Submitted
            : true) && application.accountId,
      );
      const incompleteApplication = dataToConsider.find(application =>
        application.applicationType === ApplicationType.Ev
          ? application.status === ApplicationStatus.Completed &&
            application.job?.charges
            ? application.job.charges.amountDue > 0 ||
              !application.job.charges.isBillGenerated
            : true
          : true,
      );
      if (incompleteApplication) {
        setApplicationInView(incompleteApplication);
      }
    }
  };

  const getApplicationType = () =>
    applicationInView?.applicationType === ApplicationType.Er
      ? content.get('EV_REBATE')
      : content.get('EV_CHARGING');

  const getApplicationStageText = () => {
    let initialPayments = applicationInView?.job?.customerPayments;
    if (applicationInView?.job?.charges?.isBillGenerated) {
      const billDate = moment(applicationInView?.job?.charges?.billDate);
      initialPayments = applicationInView?.job?.customerPayments?.filter(p =>
        moment(p.paymentDate).isBefore(billDate),
      );
    }

    if (
      applicationInView?.applicationType === ApplicationType.Er ||
      applicationInView?.status === ApplicationStatus.Created ||
      applicationInView?.status === ApplicationStatus.InProgress
    ) {
      return content.get('COMPLETE_YOUR_APPLICATION');
    } else if (applicationInView?.job?.quote?.status === QuoteStatus.Created) {
      return content.get('ACCEPT_YOUR_QUOTE');
    } else if (
      applicationInView?.job?.quote?.status &&
      [QuoteStatus.Accepted, QuoteStatus.FinancingConfirmed].includes(
        applicationInView?.job?.quote?.status,
      ) &&
      amountDue > 0 &&
      isBillPaymentRoute
    ) {
      return inlineRichText(content.get('PAY_DUE_AMOUNT'), {
        due_date: applicationInView?.job?.charges?.isBillGenerated
          ? formatDateString(applicationInView?.job?.charges?.dueDate)
          : formatDateString(applicationInView?.job?.quote?.expiresAt),
      });
    } else if (
      applicationInView?.job?.quote?.status === QuoteStatus.Accepted &&
      initialPayments?.length === 0
    ) {
      if (
        moment().isBefore(
          moment(applicationInView?.job?.quote?.expiresAt).subtract(7, 'days'),
        )
      ) {
        return inlineRichText(content.get('COMPLETE_INITIAL_PAYMENT_MSG'), {
          lead_type: applicationInView?.job?.leadType,
          amount: toCurrencyString(amountDue || 0, true),
        });
      } else if (
        moment().isAfter(
          moment(applicationInView?.job?.quote?.expiresAt).subtract(7, 'days'),
        ) &&
        moment().isBefore(applicationInView?.job?.quote?.expiresAt)
      ) {
        return inlineRichText(content.get('QUOTE_ABOUT_TO_EXPIRE_MSG'), {
          lead_type: applicationInView?.job?.leadType,
          amount: toCurrencyString(amountDue || 0, true),
          due_date: formatDateString(applicationInView?.job?.charges?.dueDate),
        });
      }
    } else if (
      applicationInView?.job?.quote?.status === QuoteStatus.FinancingConfirmed
    ) {
      if (amountDue === 0 && !applicationInView.job?.charges?.isBillGenerated) {
        return content.get('VIEW_YOUR_PROJECT_DETAILS');
      } else if (
        applicationInView?.job?.charges?.isBillGenerated &&
        applicationInView?.job?.charges?.amountDue > 0 &&
        moment().isBefore(applicationInView?.job?.charges?.dueDate)
      ) {
        return inlineRichText(content.get('FINAL_PAYMENT_NOT_MADE_MSG'), {
          lead_type: applicationInView?.job?.leadType,
          amount: toCurrencyString(amountDue || 0, true),
          date: formatDateString(applicationInView?.job?.charges?.dueDate),
        });
      } else if (
        applicationInView.job.charges?.isBillGenerated &&
        applicationInView.job.charges.amountDue > 0 &&
        moment().isAfter(applicationInView?.job?.charges?.dueDate)
      ) {
        return inlineRichText(content.get('PGE_PROJECT_IS_PAST_DUE'), {
          lead_type: applicationInView?.job?.leadType,
          amount: toCurrencyString(amountDue || 0, true),
        });
      }
    } else if (
      applicationInView?.status === ApplicationStatus.Submitted ||
      applicationInView?.job?.status === JobStatus.Created ||
      applicationInView?.job?.status === JobStatus.Accepted ||
      applicationInView?.job?.status === JobStatus.Rejected
    ) {
      return content.get('QUOTE_REQUESTED');
    }
  };

  if (
    !applicationInView ||
    (amountDue <= 0 && isBillPaymentRoute) ||
    (applicationInView?.job?.charges?.isBillGenerated &&
      applicationInView?.job?.charges?.amountDue === 0) // Last condition means all amount settled, so don't display the banner
  ) {
    return null;
  }

  return (
    <Collapse in={true}>
      <Box
        className={bodyContainer}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (
            applicationInView?.job?.quote?.status === QuoteStatus.Accepted &&
            amountDue > 0 &&
            isBillPaymentRoute
          ) {
            void navigate(
              //TODO: Remove the condition and navigate to payment page, when the boolean flag (isBillingSubmitted) is implemented.
              applicationInView?.job?.charges?.isBillGenerated
                ? getPOPUrl(
                    ROUTES.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO,
                    applicationInView?.id,
                  )
                : getPOPUrl(
                    ROUTES.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS,
                    applicationInView?.id,
                  ),
            );
          } else {
            void navigate(ROUTES.PGE_PLUS_EV_CHARGERS_WELCOME_BACK, {
              state: { accountNumber: applicationInView?.accountId },
            });
          }
        }}
      >
        <Box className={textContent}>
          <IconChargerLevel2 height={45} width={45} />
          <Typography
            css={{
              color: colors.sparkBlue,
              fontWeight: 500,
              marginLeft: '10px',
            }}
          >
            PGE+ {getApplicationType()}:{isMobile && <br />}{' '}
            {getApplicationStageText()}
          </Typography>
        </Box>
        <ChevronRight />
      </Box>
    </Collapse>
  );
};

export default PendingApplicationBanner;
