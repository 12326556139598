//
import { format } from 'date-fns';
import {
  ServiceAddress,
  AccountDetail,
  Address,
  PremiseInfo,
} from '../__generated__/pge-types';
import stateNameMap from './stateNameMap';
import { isSafari, browserVersion } from 'react-device-detect';
import { SupportedLanguages } from '../providers/LanguageProvider';

export const parsePhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/[^\d.]/g, '');

export const displayPhoneNumberFormat = (phone: any) => {
  const phoneNumber = String(phone);
  if (phone === null) {
    return '';
  }
  if (String(phone).length !== 10) {
    return '';
  }
  const first = phoneNumber.slice(0, 3);
  const second = phoneNumber.slice(3, 6);
  const third = phoneNumber.slice(6, 10);
  return `(${first}) ${second}-${third}`;
};

export const formatSocialSecurity = (ssn: string) => {
  let val = ssn;
  val = val.replace(/\D/g, '');
  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/-(\d{2})/, '-$1-');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
};

export function formatServiceAddress(
  serviceAddress: ServiceAddress | PremiseInfo | Address | null,
) {
  if (!serviceAddress) {
    return null;
  }
  const addressParts = determineAddressParts(serviceAddress);
  return `${addressParts.addressPart}, ${addressParts.cityPart}`;
}

export function determineAddressParts(
  serviceAddress: ServiceAddress | Address | PremiseInfo,
) {
  const cityPart = [
    serviceAddress?.city,
    serviceAddress?.state,
    serviceAddress?.postal,
  ]
    .join(' ')
    .trim();

  return { addressPart: serviceAddress?.addressLine1, cityPart: cityPart };
}

export const maskAccountNumber = (
  accountNumber?: string | undefined | null,
): string => {
  if (!!!accountNumber || accountNumber.length !== 10) {
    return '';
  } else {
    return '******' + accountNumber.slice(6, 10);
  }
};

type MaskOptions = {
  showingLength?: number;
  maskCharacter?: string;
};

export function mask(
  value: string | null | undefined,
  { showingLength = 4, maskCharacter = '*' }: MaskOptions = {},
) {
  if (!value) {
    return '';
  }
  if (value.length <= showingLength) {
    return value;
  }
  const maskLength = value.length - showingLength;

  return maskCharacter.repeat(maskLength) + value.slice(-showingLength);
}

export const displayAccountIdentifier = (account: AccountDetail) => {
  if (Boolean(account.description)) {
    return `${account.accountNumber} (${account.description})`;
  }
  return account.accountNumber;
};

export const displayCustomerName = (account: AccountDetail) => {
  const parsedName = account.mainCustomerName.split(',');

  if (parsedName.length === 1) {
    return account.mainCustomerName.trim();
  }

  parsedName[0] = parsedName[0].trim();
  parsedName[1] = parsedName[1].trim();

  if (parsedName[1].indexOf(' ') > -1) {
    const parsedFirstAndMiddleNames = parsedName[1].split(' ');
    return `${parsedFirstAndMiddleNames[0].trim()} ${parsedFirstAndMiddleNames[1].trim()} ${
      parsedName[0]
    }`;
  }

  return `${parsedName[1]} ${parsedName[0]}`;
};

export const dateSuffixMap = (t: any, value: number | null | undefined) => {
  if (!value) {
    return '';
  }

  const map: { [k: string]: () => {} } = {
    '1': () => t('FIRST_SUFFIX'),
    '2': () => t('SECOND_SUFFIX'),
    '3': () => t('THIRD_SUFFIX'),
  };
  const firstDigit = value.toString().substring(0, 1);
  const lastDigit = value.toString().substr(-1);

  // handle double-digit dates, ignoring "teens"
  if (
    value.toString().length > 1 &&
    firstDigit.toString() !== '1' &&
    map[parseInt(lastDigit)]
  ) {
    return map[parseInt(lastDigit)]();
  }

  // single-digit dates
  if (value.toString().length === 1 && map[parseInt(lastDigit)]) {
    return map[parseInt(lastDigit)]();
  }

  return t('OTHER_SUFFIX');
};

export const toDateString = (
  value: Date | string | number | null | undefined,
  dateFormat: string = 'MMM. dd, yyyy',
  language?: SupportedLanguages,
): string => {
  if (!value || value === '0001-01-01T00:00:00') {
    return '--';
  }
  // if using format "MMM." "May" appears as "May.",
  // the "replace" accounts for that
  let date = new Date();
  if (isSafari && Number(browserVersion) < 14) {
    if (typeof value === 'string' && value.toString().indexOf('T') > -1) {
      const dateOnlySafari = value.toString().split('T');
      const convertedDateForSafari = `${dateOnlySafari[0].toString()}T12:00:00.000`;
      date = new Date(convertedDateForSafari);
    }
  } else {
    date = new Date(value);
  }
  const month = date.getMonth();

  const result = (() => {
    if (month === 2) {
      return format(new Date(value), dateFormat).replace('Mar.', 'March');
    }

    if (month === 3) {
      return format(new Date(value), dateFormat).replace('Apr.', 'April');
    }

    if (month === 5) {
      return format(new Date(value), dateFormat).replace('Jun.', 'June');
    }

    if (month === 6) {
      return format(new Date(value), dateFormat).replace('Jul.', 'July');
    }

    return format(new Date(value), dateFormat).replace('May.', 'May');
  })();

  if (language === SupportedLanguages.Spanish) {
    const spanishMonth = new Intl.DateTimeFormat('es-ES', {
      month:
        month === 2 ||
        month === 3 ||
        month === 5 ||
        month === 6 ||
        month === 4 ||
        month === 0
          ? 'long'
          : 'short',
    }).format(new Date(date));
    const translatedMonth = result.split(' ');
    translatedMonth[0] =
      toSentenceCase(spanishMonth).trim() +
      (spanishMonth.length === 3 ? '.' : '');
    return translatedMonth.join(' ');
  }
  return result;
};

export const toDateAtTimeString = (
  value: Date | string | number | null | undefined,
): string => {
  return toDateString(value, 'MMM dd @ hh:mm a');
};

export const toDateStringFullMonthName = (
  value: Date | string | number | null | undefined,
): string => {
  if (!value || value === '0001-01-01T00:00:00') {
    return '--';
  }

  let date = new Date();
  if (typeof value === 'string' && value.toString().indexOf('Z') > -1) {
    const dateOnly = value.toString().split('T');
    const convertedDate = `${dateOnly[0].toString()}T12:00:00.000`;
    date = new Date(convertedDate);
  } else {
    date = new Date(value);
  }

  if (isSafari && Number(browserVersion) < 14) {
    if (typeof value === 'string' && value.toString().indexOf('T') > -1) {
      const dateOnlySafari = value.toString().split('T');
      const convertedDateForSafari = `${dateOnlySafari[0].toString()}T12:00:00.000`;
      date = new Date(convertedDateForSafari);
    }
  }
  const month = date.getMonth();
  const fullNameMonths = [2, 3, 4, 5, 6];
  const isFullNameMonth = fullNameMonths.includes(month);

  if (isFullNameMonth) {
    return format(new Date(date), 'MMMM dd, yyyy');
  }
  return format(new Date(date), 'MMM. dd, yyyy');
};

export const toCurrencyString = (
  value: number,
  showIfZero: boolean,
  hideCents?: boolean,
  stripCentsIfWholeNumber?: boolean,
): string => {
  if (stripCentsIfWholeNumber) {
    return Number.isInteger(value)
      ? value?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : value?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  } else {
    return value
      ? value?.toLocaleString(undefined, {
          minimumFractionDigits: hideCents ? 0 : 2,
          maximumFractionDigits: hideCents ? 0 : 2,
        })
      : showIfZero
      ? '0.00'
      : '';
  }
};

export const toCurrencyDisplayFormat = (
  value: number,
  showIfZero: boolean,
  showCreditOrZero: 'CR' | 'Zero',
): string => {
  if (value < 0) {
    const formattedValue =
      showCreditOrZero === 'CR'
        ? `${value
            .toFixed(2)
            .toString()
            .replace('-', '')} CR`
        : '0.00';

    return formattedValue;
  }

  return value
    ? value?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : showIfZero
    ? '0.00'
    : '';
};

export const maskAllButLast = (
  numberOfCharactersToleaveUnmasked: number,
  value: string | null | undefined,
  maskCharacter: string = '*',
): string => {
  if (value === undefined || value === null) {
    return '';
  }

  const valueLength = value?.length;

  if (valueLength < numberOfCharactersToleaveUnmasked) {
    return maskCharacter.repeat(valueLength);
  }

  const maskLength = value.length - numberOfCharactersToleaveUnmasked;
  return (
    maskCharacter.repeat(maskLength) +
    value.substr(maskLength, numberOfCharactersToleaveUnmasked)
  );
};

export const toSentenceCase = (value: string): string => {
  const parts = value.split(' ');
  const [firstWord, ...tail] = parts;
  const properFirstWord = Array.from(firstWord)
    .map((letter: string, index: number) => {
      if (index === 0) {
        return letter.toUpperCase();
      }

      return letter.toLowerCase();
    })
    .join('');
  return `${properFirstWord} ${tail
    .map((word: string) => word.toLowerCase())
    .join(' ')}`;
};

export const getFormattedToday = (date = new Date()) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  return month + '/' + day + '/' + year;
};

export const getFormattedOneYearDate = (formattedDate: Date) => {
  const year = formattedDate.getFullYear();
  const month = formattedDate.getMonth();
  const day = formattedDate.getDate();
  const oneYearDate = new Date(year + 1, month, day);
  return format(oneYearDate, 'MM/dd/yyyy');
};

export function formatDate(date: Date, dateFormat: string = 'MM/dd/yyyy') {
  return format(date, dateFormat);
}
/***
 * Adds the oridinal suffix to the number.
 * Examples:
 * For 1 it adds st resturning 1st.
 * For 3 it adds rd returning 3rd.
 * For 4 it adds th returning 4th.
 * For 101 it adds st returning 101st.
 *
 * Note, this has unexpected results for larger numbers in javascript
 * I noticed that the smallest number that stops working is over 10 quadrillion
 * 1000000000000001 % 10 > ok
 * 10000000000000001 % 10 > nok
 */
export function addOrdinalSuffixToNumber(i: number) {
  const j = i % 10;
  if (j === 1 && i !== 11) {
    return i + 'st';
  }
  if (j === 2 && i !== 12) {
    return i + 'nd';
  }
  if (j === 3 && i !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export const substrNFromEnd = (len: number, value?: string | null) =>
  !value || value?.length <= len ? value : value?.substr(value?.length - len);

const canadaRegexp = /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/;
// Get the country of the given postal code
export function getPostalCountry(postal: string): string {
  if (canadaRegexp.test(postal)) {
    return 'CAN';
  } else {
    return 'USA';
  }
}

export function getStateName(state: string) {
  return stateNameMap[state] || '';
}

export function replaceAsteriskForMarkdown(value?: string | null) {
  return value?.replace(/\*/g, '&ast;');
}

export function truncateWithEllipses(value?: string | null, len?: number) {
  return value && len && len > 3 && value?.length >= len
    ? `${value?.substring(0, len - 3)}...`
    : value;
}

export const hex2rgb = (
  hexstr: string,
): { r: number; g: number; b: number } => {
  // handles only rgb (not alpha)

  const hex = hexstr.startsWith('#') ? hexstr.substring(1) : hexstr;

  const hexarr = (hex.length <= 3
    ? hex.padEnd(3, '0')
    : hex.length <= 6
    ? hex.padEnd(6, '0')
    : hex
  ).split('');

  const [r1 = 0, r2 = 0, g1 = 0, g2 = 0, b1 = 0, b2 = 0] =
    hexarr.length === 3 ? hexarr.flatMap(x => [x, x]) : hexarr;

  return {
    r: parseInt(`${r1}${r2}`, 16),
    g: parseInt(`${g1}${g2}`, 16),
    b: parseInt(`${b1}${b2}`, 16),
  };
};

// convert comma format currency string
export const getFormattedCurrencyString = (value: any) => {
  return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
